import React from 'react';
import './header.css';
import people from '../../assets/people.png';
import ai from '../../assets/ai.png';

const Header = () => {
  return (
    <div className='gpt3__header section__padding' id='home'>
      <div className="gpt3__header-content">

        <h1 className="gradient__text"> Lets Build Something Amazing with GPT-3 OpenAI</h1>
        <p>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Unde nobis minima eum facilis maxime commodi ad, ex veniam consequatur magnam id, a pariatur odio qui omnis nihil. Quos, a dicta!
        </p>

        <div className="gpt3__header-content__input">
          <input type="email" name="" placeholder='your email address' id="" />
          <button type='button'>Get Started</button>
        </div>

        <div className="gpt3__header-content__people">
          <img src={people} alt="people" />
          <p>1600 people requested access in the last 24 hours</p>
        </div>
      </div>
      <div className="gpt3__header-image">
          <img src={ai} alt="ai" />
        </div>
    </div>
  )
}

export default Header