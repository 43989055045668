import React from 'react';
import possibilityImage from '../../assets/possibility.png'
import './possibility.css';

const Possibility = () => {
  return (
    <div className='gpt3__possibility section__padding' id='possibility'>
      <div className="gpt3__possibility-image">
        <img src={possibilityImage} alt="possibilityImage" />
      </div>
      <div className="gpt3__possibility-content">
        <h4>Request early access to get started.</h4>
        <h1 className="gradient__text">The possibilities are beyond your imagination</h1>
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus ad necessitatibus eius incidunt totam doloribus cum omnis quasi optio suscipit, aperiam cumque placeat corrupti rerum. Cumque incidunt laudantium ex soluta.</p>
        <h4>Request early access to get started.</h4>
      </div>
    </div>
  );
}

export default Possibility