import React from 'react';
import { Feature } from '../../components';
import './features.css';

const  featuresData = [
  {
    title: 'Improving end distrusts instantly',
    text: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Voluptate totam odio iusto porro'
  },
  {
    title: 'Become the tended active',
    text: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Voluptate totam odio iusto porro'
  },
  {
    title: 'A third title',
    text: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Voluptate totam odio iusto porro'
  },
  {
    title: 'React.Js used for this project',
    text: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Voluptate totam odio iusto porro'
  }
]

const Features = () => {
  return (
    <div className='gpt3__features section__padding' id='features'>
      <div className="gpt3__features-heading">
        <h1 className="gradient__text">The Future is Now and you just need to realize it. Step into the future today & make it Happen.</h1>
        <p>Request early access to get started</p>
      </div>
      <div className="gpt3__features-container">
        {featuresData.map((item, index) => (
          <Feature title={item.title} text={item.text} key={item.title + index} />
        ))}
      </div>
    </div>
  )
}

export default Features