import React from 'react';
import { Feature } from '../../components';
import './whatGPT3.css';

const WhatGPT3 = () => {
  return (
    <div className='gpt3__whatgpt3 section__margin' id='wgpt3'>
      <div className="gpt3__whatgpt3-feature">
        <Feature title="What is GPT-3" text="
Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, dolore ducimus blanditiis error dignissimos minima tempore voluptatem, eius repudiandae dolor minus earum est quod accusamus culpa, libero ipsa repellendus. Eligendi"/>
      </div>
      <div className='gpt3__whatgpt3-heading'>
        <h1 className='gradient__text'>The possibilities are beyond your imagination</h1>
        <p>Explore the Library</p>
      </div>
      <div className="gpt3__whatgpt3-container">
        <Feature title="Chatbots" text="
Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, dolore ducimus blanditiis error dignissimos minima."/>
        <Feature title="Knowledgbase" text="
Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, dolore ducimus blanditiis error dignissimos minima." />
        <Feature title="Education" text="
Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat, dolore ducimus blanditiis error dignissimos minima." />
      </div>
    </div>
  )
}

export default WhatGPT3